<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          User roles
        </h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert align-v="center" variant="warning" :show="saveMessage !== ''">{{ this.saveMessage }}</b-alert>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab v-for="(role, index) in roles" :key="index" :title="index">
        <b-form-group v-for="(page, pageIndex) in role" :key="pageIndex" :label="pageIndex">
          <b-checkbox-group :options="Object.keys(page)"></b-checkbox-group>
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
  export default { 
    data() {
      return {
        roles: {
          "user": {
            "features": {"read": false, "write": false, "edit": false, "delete": false},
            "home-page": {"read": false, "write": false, "edit": false, "delete": false},
            "header": {"read": false, "write": false, "edit": false, "delete": false},
            "static-pages": {"read": false, "write": false, "edit": false, "delete": false},
            "styling": {"read": false, "write": false, "edit": false, "delete": false},
            "translations": {"read": false, "write": false, "edit": false, "delete": false},
            "livestreams": {"read": false, "write": false, "edit": false, "delete": false},
            "whereby": {"read": false, "write": false, "edit": false, "delete": false},
            "attachments": {"read": false, "write": false, "edit": false, "delete": false},
            "users": {"read": false, "write": false, "edit": false, "delete": false},
            "user-roles": {"read": false, "write": false, "edit": false, "delete": false},
            "registration": {"read": false, "write": false, "edit": false, "delete": false},
            "notifications": {"read": false, "write": false, "edit": false, "delete": false},
            "freshchat": {"read": false, "write": false, "edit": false, "delete": false},
            "mail-templates": {"read": false, "write": false, "edit": false, "delete": false},
            "send-mail": {"read": false, "write": false, "edit": false, "delete": false},
          }, 
          "moderator": {
            "features": {"read": false, "write": false, "edit": false, "delete": false},
            "home-page": {"read": false, "write": false, "edit": false, "delete": false},
            "header": {"read": false, "write": false, "edit": false, "delete": false},
            "static-pages": {"read": false, "write": false, "edit": false, "delete": false},
            "styling": {"read": false, "write": false, "edit": false, "delete": false},
            "translations": {"read": false, "write": false, "edit": false, "delete": false},
            "livestreams": {"read": false, "write": false, "edit": false, "delete": false},
            "whereby": {"read": false, "write": false, "edit": false, "delete": false},
            "attachments": {"read": false, "write": false, "edit": false, "delete": false},
            "users": {"read": false, "write": false, "edit": false, "delete": false},
            "user-roles": {"read": false, "write": false, "edit": false, "delete": false},
            "registration": {"read": false, "write": false, "edit": false, "delete": false},
            "notifications": {"read": false, "write": false, "edit": false, "delete": false},
            "freshchat": {"read": false, "write": false, "edit": false, "delete": false},
            "mail-templates": {"read": false, "write": false, "edit": false, "delete": false},
            "send-mail": {"read": false, "write": false, "edit": false, "delete": false},
          },
          "admin": {
            "features": {"read": true, "write": true, "edit": true, "delete": true},
            "home-page": {"read": true, "write": true, "edit": true, "delete": true},
            "header": {"read": true, "write": true, "edit": true, "delete": true},
            "static-pages": {"read": true, "write": true, "edit": true, "delete": true},
            "styling": {"read": true, "write": true, "edit": true, "delete": true},
            "translations": {"read": true, "write": true, "edit": true, "delete": true},
            "livestreams": {"read": true, "write": true, "edit": true, "delete": true},
            "whereby": {"read": true, "write": true, "edit": true, "delete": true},
            "attachments": {"read": true, "write": true, "edit": true, "delete": true},
            "users": {"read": true, "write": true, "edit": true, "delete": true},
            "user-roles": {"read": true, "write": true, "edit": true, "delete": true},
            "registration": {"read": true, "write": true, "edit": true, "delete": true},
            "notifications": {"read": true, "write": true, "edit": true, "delete": true},
            "freshchat": {"read": true, "write": true, "edit": true, "delete": true},
            "mail-templates": {"read": true, "write": true, "edit": true, "delete": true},
            "send-mail": {"read": true, "write": true, "edit": true, "delete": true},
          }, 
        }
      }
    }
  }
</script>